import React from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import ThankYou from "../../components/ConversionPath/demo-requested";
// import Image from '../../components/image';
import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
import { restaurantTestimonials } from "../../data/restaurant-data";

export default function ThankYouPage() {
  return (
    <Layout isLanding phoneNumber="+18775594225" phoneText="(877) 559-4225">
      <SEO title="Thank you - your demo request has been received | SpotOn " />

      <ThankYou
        phoneNumber=""
        phoneText=""
        thankYouSubheader=""
        thankYouMessage="We'll keep you updated with the progress of the submitted referreal"
      />

      <TestmonialReviews sectionData={restaurantTestimonials} />
    </Layout>
  );
}
